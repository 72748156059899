import React, {Component, lazy, Suspense} from 'react';
import './App.css';

// google analytics
import ReactGA from 'react-ga';
// components
// import Header from './components/header/header';
const Header = lazy(() => import('./components/header/header'));
// import Events from './components/events/events';
const Events = lazy(() => import('./components/events/events'));
const FeaturedEvents = lazy(()=> import('./components/featuredEvents/featuredEvents'));
const FeaturedVenues = lazy(()=> import('./components/featuredVenues/featuredVenues'));
const PwaCTA = lazy(()=> import('./components/pwaCTA/pwaCTA'));
const Footer = lazy(()=> import('./components/footer/footer'));

ReactGA.initialize('UA-157459271-1');
ReactGA.pageview('/homepage');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { isMobileDevice: false }
  }

  isMobileDevice = () => {
    if(typeof window.orientation !== "undefined" || (navigator.userAgent.indexOf('IEMobile') !== -1)) {
      this.setState({isMobileDevice: true}, ()=>{console.log(this.state.isMobileDevice)})
    }
  }
  componentDidMount() {
    this.isMobileDevice()
  }

  render() { 
    let isMobileDevice = this.state.isMobileDevice;
    return ( 
      <div className="App"> 
        <Suspense fallback={<div className="loader2">Loading...</div>}>
          <Header />
          <Events />
          <FeaturedEvents />
          <FeaturedVenues />
          {isMobileDevice ? <PwaCTA /> : ''}
          <Footer />
        </Suspense>
      </div>
    );
  }
}

export default App;
